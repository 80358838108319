import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getPushersContent = createAsyncThunk(
  'pushers/getContent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/pushers');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch pushers');
    }
  }
);

export const addPushersContent = createAsyncThunk(
  'pushers/addContent',
  async (pusher, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/pushers/add', pusher);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to add pusher');
    }
  }
);

export const updatePushersContent = createAsyncThunk(
  'pushers/updateContent',
  async (pusher, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/pushers/update/${pusher._id}`, pusher);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update pusher');
    }
  }
);

export const deletePushersContent = createAsyncThunk(
  'pushers/deleteContent',
  async (pusherId, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/pushers/delete/${pusherId}`);
      return pusherId;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete pusher');
    }
  }
);

export const pusherSlice = createSlice({
  name: 'pushers',
  initialState: {
    isLoading: false,
    pushers: [],
    selectedPusher: null,
  },
  reducers: {
    setSelectedPusher: (state, action) => {
      state.selectedPusher = action.payload;
    },
    setPushers: (state, action) => {
      state.pushers = action.payload;
    },
  },
  
  extraReducers: (builder) => {
    builder
      .addCase(getPushersContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPushersContent.fulfilled, (state, action) => {
        state.pushers = action.payload;
        state.isLoading = false;
      })
      .addCase(getPushersContent.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addPushersContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPushersContent.fulfilled, (state, action) => {
        state.pushers.push(action.payload.data)
        state.isLoading = false
      })
      .addCase(addPushersContent.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updatePushersContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePushersContent.fulfilled, (state, action) => {
        const index = state.pushers.findIndex(device => device._id === action.payload.data._id)
        if (index !== -1) {
          state.pushers[index] = action.payload.data
        }
        state.isLoading = false;
      })
      .addCase(updatePushersContent.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deletePushersContent.pending, (state) => {
        state.isLoading = true;
        state.selectedPusher  = null
      })
      .addCase(deletePushersContent.fulfilled, (state, action) => {
        state.pushers = state.pushers.filter(pusher => pusher._id !== action.payload);
        state.isLoading = false;
      })
      .addCase(deletePushersContent.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const { setSelectedPusher , setPushers } = pusherSlice.actions;
export default pusherSlice.reducer;
