import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to add license content
export const addLicenseContent = createAsyncThunk(
  "licenses/addContent",
  async (newLicense, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/api/license/createLicenseForUser",
        newLicense
      );
      return response.data; // Assuming response.data contains the added license
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to add license");
    }
  }
);

// Async thunk to delete a license
export const deleteLicense = createAsyncThunk(
  "licenses/deleteLicense",
  async (licenseId, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/license/delete/${licenseId}`);
      return licenseId; // Return the license ID to be deleted
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to delete license"
      );
    }
  }
);

export const revokeLicense = createAsyncThunk(
  "licenses/revoke",
  async (licenseId, { rejectWithValue }) => {
    try {
   const response =   await axios.put(`/api/license/revoke/${licenseId}`);
      return response.data; // Return the license ID to be deleted
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to delete license"
      );
    }
  }
);

// Async thunk to fetch licenses
export const getLicensesContent = createAsyncThunk(
  "licenses/getContent",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/license/licenses");
      return response.data; // Assuming response.data contains the licenses
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "Failed to fetch licenses"
      );
    }
  }
);

// License slice
export const licenseSlice = createSlice({
  name: "licenses",
  initialState: {
    isLoading: false,
    licenses: [],
    error: null, // Added to track errors
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLicensesContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getLicensesContent.fulfilled, (state, action) => {
        state.licenses = action.payload.licenses || [];
        state.isLoading = false;
      })
      .addCase(getLicensesContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch licenses";
      })
      .addCase(addLicenseContent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addLicenseContent.fulfilled, (state, action) => {
        state.licenses = [...state.licenses, action.payload.license];
        state.isLoading = false;
      })
      .addCase(addLicenseContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to add license";
      })
      .addCase(deleteLicense.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteLicense.fulfilled, (state, action) => {
        // Filter out the license that was deleted
        state.licenses = state.licenses.filter(
          (license) => license._id !== action.payload
        );
        state.isLoading = false;
      })
      .addCase(deleteLicense.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to delete license";
      })
      .addCase(revokeLicense.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(revokeLicense.fulfilled, (state, action) => {
        // update out the license that was updated
        state.licenses = state.licenses.map((license) =>
            license._id === action.payload.license._id
              ? { ...license, ...action.payload.license } // Update the matching license
              : license // Keep others unchanged
          );
        
        state.isLoading = false;
      })
      .addCase(revokeLicense.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to revoke license";
      });
  },
});

export default licenseSlice.reducer;
