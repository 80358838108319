import axios from "axios";
import { jwtDecode } from "jwt-decode"; // Make sure to install this package via npm or yarn

const initializeApp = () => {
  // Setting base URL for all API requests via axios
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token); // Decoding the token
        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp && decodedToken.exp < currentTime) {
          console.warn("Token expired. Logging out...");
          // Perform logout or token refresh logic
          localStorage.removeItem("token");
          window.location.href = "/login"; // Redirect to login page
        }
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  };

  // Check token expiration every minute
  setInterval(checkTokenExpiration, 60 * 1000); // 60 seconds

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // Dev code
  } else {
    // Prod build code

    // Removing console.log from prod
    console.log = () => {};

    // Initialize analytics here
  }
};

export default initializeApp;
